<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal-form-gan-oferta" style="overflow: auto">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <b style="font-style: italic; font-size: 13px">
              <h5 class="modal-title text-white" v-if="subasta">
                Volumenes ofertados por cliente |
                <b style="font-style: italic; font-size: 12px">
                  Subasta # {{ subasta.id }} crudos Semanas
                  {{ numeroSemanaIni }} y {{ numeroSemanaFin - 1 }} ({{
                    subasta.fecha_ini
                  }}
                  / {{ subasta.fecha_fin }})</b
                >
              </h5>
            </b>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal1"
              ref="closeModal1"
              @click="limpiarModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="subasta">
              <div class="col-lg-3 col-6">
                <div
                  class="small-box alert-default-success p-1"
                  style="min-height: 105px"
                >
                  <div class="inner">
                    <div class="col-md-11" style="font-size: 10px">
                      <h4><b>Producto</b></h4>
                      <div v-for="item in subasta.productos" :key="item.id">
                        Crudo {{ item.producto.nombre }} ({{ item.api }} API y
                        {{ item.s }} %S)
                      </div>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div
                  class="small-box alert-default-warning p-1"
                  style="min-height: 105px"
                >
                  <div class="inner">
                    <div class="col-md-11" style="font-size: 10px">
                      <h4><b>Punto de Entrega</b></h4>
                      <div v-for="item in subasta.productos" :key="item.id">
                        Crudo {{ item.producto.nombre }} :
                        {{ item.sitio.dirreccion }}
                      </div>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-location"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div
                  class="small-box alert-default-info p-1"
                  style="min-height: 105px"
                >
                  <div class="inner">
                    <div class="col-md-11" style="font-size: 10px">
                      <h4><b>Volumen</b></h4>
                      <div v-for="item in subasta.productos" :key="item.id">
                        Crudo {{ item.producto.nombre }} : Hasta
                        {{ item.cant_vh }} Ctks/D ({{ item.volumen }} BD)
                      </div>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-soup-can-outline"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div
                  class="small-box alert-default-danger p-1"
                  style="min-height: 105px"
                >
                  <div class="inner">
                    <div class="col-md-11">
                      <h4><b>Vigencia</b></h4>
                      <p style="font-size: 10px">
                        Semana S (Comprendida de lunes a domingo) <br />
                        Semanas {{ numeroSemanaIni }} y
                        {{ numeroSemanaFin - 1 }}
                        ({{ subasta.fecha_ini }} / {{ subasta.fecha_fin }})
                      </p>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="subasta">
              <div
                class="card mb-2"
                v-for="(prod, indicePro) in productsAgroups"
                :key="prod.id"
                :class="getCardColorCl(indicePro)"
              >
                <div class="card-header p-2 pl-4">
                  <h4 class="card-title w-100">
                    <a class="d-block w-100 collapsed">
                      <i class="fas fa-coins fa-lg"> </i>
                      <b> {{ prod[0].producto.nombre }}</b>
                    </a>
                  </h4>
                </div>
                <div
                  class="card-body table-responsive"
                  v-if="prod[0].ofertas.length > 0"
                >
                  <div class="row">
                    <div
                      class="card mb-2 col-md-12"
                      v-for="product /* indiProduct */ in productsAgroups[
                        indicePro
                      ]"
                      :key="product.id"
                    >
                      <div class="card-header">
                        <i class="fas fa-calendar"></i>
                        {{ product.fecha_ini }} / {{ product.fecha_fin }}
                        <b> | </b> {{ product.cant_vh }} Ctks/D ({{
                          product.volumen
                        }}
                        BD)
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn bg-danger"
                            style="cursor: pointer"
                            @click="limpiarOferts(product)"
                            :disabled="hasWinner()"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-navy"
                            style="cursor: pointer"
                            @click="automaticOferts(product)"
                            :disabled="hasWinner()"
                          >
                            <i class="fas fa-users-cog"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div
                          class="card mb-2 col-md-12"
                          v-for="(ofert, indiOfert) in product.ofertas"
                          :class="indiOfert == 0 ? 'alert-default-primary' : ''"
                          :key="ofert.id"
                        >
                          <div class="card-header">
                            <div class="row">
                              <div
                                class="col-md-7"
                                v-if="
                                  ofert.cliente !== null &&
                                    ofert.cliente !== undefined
                                "
                              >
                                <i class="fas fa-user-tie fa-lg"></i>
                                <b> Cliente: </b>{{ ofert.cliente.razon_social
                                }}<b> | </b>
                                <i class="fas fa-dollar-sign fa-lg"></i>
                                <b> Oferta:</b> {{ ofert.precio_oferta }} US$B
                              </div>
                              <div class="col-md-2 btn-group float-right">
                                <b class="mt-2" style="font-size: 11px"
                                  >Proponente Adjudicado?
                                </b>
                                <input
                                  type="checkbox"
                                  class="form-control p-0 m-2"
                                  v-model="ofert.ganador"
                                  id="ganador"
                                  :disabled="ofert.isDisabledGan || hasWinner()"
                                />
                              </div>
                              <div class="col-md-2 float-right">
                                <b class="mt-1" style="font-size: 11px"
                                  >Estado</b
                                >
                                <span
                                  class="badge m-2"
                                  :class="
                                    ofert.estado == 1
                                      ? 'badge-primary'
                                      : ofert.estado == 2
                                      ? 'badge-success'
                                      : ofert.estado == 3
                                      ? 'badge-info'
                                      : ofert.estado == 5
                                      ? 'badge-warning'
                                      : ofert.estado == 6
                                      ? 'badge-danger'
                                      : ofert.estado == 7
                                      ? 'bg-navy'
                                      : 'badge-secondary'
                                  "
                                >
                                  {{ ofert.nEstadoOferta }}
                                </span>
                              </div>
                              <div class="col-md-1 btn-group float-right">
                                <button
                                  type="button"
                                  class="btn alert-default-success m-2"
                                  style="cursor: pointer"
                                  title="Reasignacion Oferta Diaria"
                                  v-if="hasWinner()"
                                  @click="llenarModalAsignDia(ofert.detalles)"
                                >
                                  <i class="fas fa-money-bill-alt"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="card-header">
                            <b style="font-style: italic; font-size: 12px">
                              Precio Oferta: Brent
                              {{
                                product.precio_piso != null
                                  ? product.nSigno +
                                    " " +
                                    product.precio_piso +
                                    " " +
                                    product.nMoneda
                                  : product.nSigno + " " + "Oferta"
                              }}</b
                            >
                          </div>
                          <div class="card-body table-responsive">
                            <table
                              class="table table-hover table-sm table-striped"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th
                                    v-for="detalles in ofert.detalles"
                                    :key="detalles.id"
                                    class="text-center"
                                  >
                                    {{ detalles.fecha }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-bold mt-3 pt-4">Ctks/D</td>
                                  <td class="text-bold">
                                    Ofertado <br /><br />
                                    Adjudicado
                                  </td>
                                  <td
                                    v-for="detalles in ofert.detalles"
                                    :key="detalles.id"
                                    class="text-center"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm col-md-6 text-center"
                                        v-model="detalles.cant_vehi"
                                        disabled
                                      />
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm col-md-6 text-center mt-2"
                                        v-model="detalles.cant_aceptada"
                                        :disabled="
                                          detalles.isDisabled ||
                                            detalles.fecha < hoy
                                        "
                                        :style="{
                                          width: getWidth(detalles.fecha),
                                        }"
                                        @change="valAdjudicado(detalles, ofert)"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" v-else>
                  <div class="card mb-2 col-md-12">
                    <div class="card-header text-center">
                      <span class="badge badge-warning">
                        No hay ofertas disponibles para este producto</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              style="cursor: pointer"
              @click="save()"
              :disabled="hasWinner()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modal-form-precios-oferta"
      ref="modalFormPreciosOferta"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header alert-default-dark pt-2 pb-2">
            <h5 class="modal-title text-bold">Re Asignación Precios Diarios</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal2"
              ref="closeModal2"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-sm table-hover">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Fecha</th>
                  <th>Precio Ofertado</th>
                  <th>Nueva Oferta</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="item in historicosPrecios"
                  :key="item.id"
                >
                  <td>{{ item.fecha }}</td>
                  <td>
                    <div v-for="hist in item.historico_ofertas" :key="hist.id">
                      {{ hist.precio_ofer }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="row">
                      <div class="col-md-3"></div>
                      <div
                        v-for="hist in item.historico_ofertas"
                        :key="hist.id"
                        class="col-md-5"
                      >
                        <input
                          type="number"
                          class="form-control form-control-sm text-center"
                          v-model="hist.precio_oferta"
                          :disabled="hist.fecha < hoy"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer btn-group float-right">
            <button
              type="button"
              class="btn alert-default-success"
              @click="saveNuevasOfertas()"
            >
              <b>Guardar</b>
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "SubastaModalGanOferta",

  data() {
    return {
      subasta: null,
      productsAgroups: null,
      hoy: moment().format("YYYY-MM-DD"),
      historicosPrecios: [],
      subasta_id: null,
    };
  },

  computed: {
    numeroSemanaIni() {
      const dateIni = new Date(this.subasta.fecha_ini); // Convert fecha_ini to a Date object
      return this.getWeekNumber(dateIni);
    },

    numeroSemanaFin() {
      const dateFin = new Date(this.subasta.fecha_fin); // Convert fecha_fin to a Date object
      return this.getWeekNumber(dateFin);
    },
  },

  methods: {
    async getIndex(id = null) {
      if (id) {
        await this.$nextTick(); // Esperar a que se actualice el DOM
        this.$parent.cargando = true;
        this.subasta = null;
        const me = this;
        axios
          .get("/api/com/subastas/" + id)
          .then(async (response) => {
            this.subasta = response.data;
            this.subasta_id = response.data.id;
            this.productsAgroups = await this.agruparProductos();
            this.$parent.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error:" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = false;
          });
      }
    },

    async automaticOferts(product) {
      let cantVhSub = parseFloat(product.cant_vh);
      for (let i = 0; i < product.ofertas.length; i++) {
        for (let j = 0; j < product.ofertas[i].detalles.length; j++) {
          product.ofertas[i].detalles[j].cant_pendiente = cantVhSub;
        }
      }
      let ofertas = product.ofertas;
      for (let i = 0; i < ofertas.length; i++) {
        let detalles = ofertas[i].detalles;
        for (let j = 0; j < detalles.length; j++) {
          if (detalles[j].cant_vehi >= ofertas[0].detalles[j].cant_pendiente) {
            detalles[j].cant_aceptada = ofertas[0].detalles[j].cant_pendiente;
            ofertas[0].detalles[j].cant_pendiente -= parseFloat(
              ofertas[0].detalles[j].cant_pendiente
            );
            detalles[j].isDisabled = true;
            if (ofertas[i].detalles[j].cant_aceptada == 0) {
              ofertas[i].ganador = 0;
              ofertas[i].isDisabledGan = true;
            } else {
              ofertas[i].ganador = 1;
              ofertas[i].isDisabledGan = true;
            }
          } else if (
            detalles[j].cant_vehi < ofertas[0].detalles[j].cant_pendiente
          ) {
            detalles[j].isDisabled = true;
            detalles[j].cant_aceptada = detalles[j].cant_vehi;
            ofertas[0].detalles[j].cant_pendiente -= parseFloat(
              detalles[j].cant_aceptada
            );
            if (ofertas[i].detalles[j].cant_aceptada == 0) {
              ofertas[i].ganador = 0;
              ofertas[i].isDisabledGan = true;
            } else {
              ofertas[i].ganador = 1;
              ofertas[i].isDisabledGan = true;
            }
          } else {
            detalles[j].cant_aceptada = 0;
          }

          if (cantVhSub == 0) {
            return;
          }
        }
      }
    },

    hasWinner() {
      if (this.subasta) {
        return this.subasta.ofertas.some(
          (ofert) => ofert.ganador !== null && ofert.ganador !== ""
        );
      }
    },

    llenarModalAsignDia(detalles) {
      this.historicosPrecios = detalles;
      window.$("#modal-form-precios-oferta").modal("show");
    },

    async saveNuevasOfertas() {
      this.$parent.cargando = true;
      await axios
        .post("api/com/subastas/saveNewOfert", this.historicosPrecios)
        .then(async (response) => {
          this.historicosPrecios = [];
          await this.getIndex(this.subasta_id);
          await this.$refs.closeModal2.click();
          this.$parent.cargando = false;
          await this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiarOferts(product) {
      for (let i = 0; i < product.ofertas.length; i++) {
        product.ofertas[i].isDisabledGan = false;
        product.ofertas[i].ganador = null;
        for (let j = 0; j < product.ofertas[i].detalles.length; j++) {
          product.ofertas[i].detalles[j].cant_pendiente = null;
          product.ofertas[i].detalles[j].cant_aceptada = null;
          product.ofertas[i].detalles[j].isDisabled = false;
        }
      }
    },

    save() {
      this.$parent.cargando = true;
      axios({
        method: "PUT",
        url: "/api/com/subastas/AsignarGanadores",
        data: {
          form: this.productsAgroups,
          subasta: { id: this.subasta.id, estado: this.subasta.estado },
        },
      })
        .then(() => {
          this.sendMail();
          this.$refs.closeModal1.click();
          this.limpiarModal();
          this.$swal({
            icon: "success",
            title: "Se han guardado exitosamente los proponentes adjudicados",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    sendMail() {
      var m = this;
      axios
        .get("/api/com/ofertaSubastas/correoGanadoresSubasta/" + m.subasta.id)
        .then(() => {
          m.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          m.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async valAdjudicado(detalles, ofert) {
      const valueAnt = detalles.cant_acept;
      const valueAcept = detalles.cant_aceptada;
      const valueOfert = detalles.cant_vehi;

      if (valueAcept > valueOfert && !this.hasWinner()) {
        await this.$swal({
          icon: "warning",
          title: "El valor digitado ha superado el ofertado!!",
          text: "Por favor validar nuevamente el valor para esta fecha",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        detalles.cant_aceptada = valueAnt;
      } else if (this.hasWinner()) {
        if (ofert.estado == 3) {
          if (detalles.postulaciones.length > 0) {
            if (detalles.postulaciones.length > valueAcept) {
              await this.$swal({
                icon: "warning",
                title: "No se puede disminuir la cantidad de cupos!!",
                text:
                  "Por favor comunicarse con " +
                  ofert.cliente.razon_social +
                  ", ya que para la fecha " +
                  detalles.fecha +
                  " cuentan con " +
                  detalles.postulaciones.length +
                  " Ctks postulados.",
                showConfirmButton: true,
              });
              detalles.cant_aceptada = valueAnt;
            } else {
              await this.$swal({
                title: "Esta seguro de modificar la cantidad de cupos?",
                text:
                  ofert.cliente.razon_social +
                  ", actualmente cuenta con " +
                  detalles.postulaciones.length +
                  " Ctks postulados para la fecha " +
                  detalles.fecha +
                  ". ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si, Aceptar!",
              }).then((result) => {
                if (result.value) {
                  this.saveDetail(detalles, ofert.id, 1);
                } else {
                  detalles.cant_aceptada = valueAnt;
                }
              });
            }
          } else {
            await this.$swal({
              title: "Esta seguro de modificar la cantidad de cupos?",
              text:
                "Una vez asignados los cupos el cliente ya puede ingresar a postular los Ctks!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Aceptar!",
            }).then((result) => {
              if (result.value) {
                this.saveDetail(detalles, ofert.id, 1);
              } else {
                detalles.cant_aceptada = valueAnt;
              }
            });
          }
        } else {
          await this.$swal({
            title: "Esta seguro de asignar cupos a este cliente?",
            text:
              ofert.cliente.razon_social +
              ", no quedo dentro de los ganadores de la subasta desea continuar con la solicitud? ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Aceptar!",
          }).then((result) => {
            if (result.value) {
              this.saveDetail(detalles, ofert.id, 2);
            } else {
              detalles.cant_aceptada = valueAnt;
            }
          });
        }
      }

      if (
        ofert.detalles.some(
          (det) =>
            det.cant_aceptada != "" &&
            det.cant_aceptada != 0 &&
            det.cant_aceptada != null
        )
      ) {
        ofert.ganador = true;
      } else {
        ofert.ganador = false;
      }
    },

    saveDetail(detail, ofertId, accion) {
      let formEnvio = {
        id: ofertId,
        det_id: detail.id,
        cant_aceptada: detail.cant_aceptada,
        accion: accion,
      };
      console.log(formEnvio);

      axios
        .post("api/com/subastas/saveDetail", formEnvio)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async agruparProductos() {
      const productsAgroups = await this.subasta.productos.reduce(
        (acc, sub) => {
          const productoId = sub.producto_liquido_id;

          if (!acc[productoId]) {
            acc[productoId] = [];
          }

          acc[productoId].push(sub);

          return acc;
        },
        {}
      );

      return Object.values(productsAgroups);
    },

    getWeekNumber(date) {
      const onejan = new Date(date.getFullYear(), 0, 1);
      const weekNum = Math.ceil(
        ((date - onejan) / 86400000 + onejan.getDay() + 1) / 7
      );
      return weekNum;
    },

    limpiarModal() {
      this.subasta = null;
      this.calendarMounted = false;
    },

    getCardColorCl(index) {
      const colors = ["card-navy", "card-secondary", "card-info", "card-dark"];
      const colorIndex = index % colors.length;
      return colors[colorIndex];
    },

    getWidth(fecha) {
      // Obtener el ancho máximo basado en la longitud del texto de la fecha
      const fechaWidth = fecha.length * 10; // Ajusta el factor según tu necesidad

      // Limitar el ancho máximo a un valor específico si es necesario
      const maxWidth = 200; // Ancho máximo permitido
      return `${Math.min(fechaWidth, maxWidth)}px`;
    },
  },
};
</script>
